import React from "react";
import "./Usercard.css";

function Usercard({ avatar, name, locations, role }) {
  return (
    <div className='userRow-wrap'>
      <div className='userRow'>
        <div className='userRow__avatar'>{avatar}</div>
        <div className='userRow__MainInfo'>{name}</div>
        <div className='userRow__Locations'>{locations}</div>
        <div className='userRow__RowActions'>{role}</div>
      </div>
    </div>
  );
}

export default Usercard;
