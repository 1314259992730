import ReactModal from "react-modal";
import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "./Popup.css";
import { db } from "../../firebase";
import firebase from "firebase";

const TrialSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
});

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px 0 0 10px",
    maxWidth: "440px",
    height: "475px",
    maxHeight: "100%",
    padding: "0px 18px 0px 30px",
    fontWeight: "500",
    zIndex: "500",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.58)",
    zIndex: "500",
  },
};

const formStyle = {
  display: "flex",
  flexDirection: "column",
};

function PopupProfile({ isOpen, setOpen }) {
  const [firstNameRec, setFirstNameRec] = useState("");
  const [lastNameRec, setLastNameRec] = useState("");

  const handleClick = () => {
    setOpen(!isOpen);
  };

  useEffect(() => {
    // db.collection("users")
    //   .doc("users")
    //   .onSnapshot((doc) => {
    //     setFirstNameRec(doc.docs.map((doc) => doc.data()));
    //   });
  }, []);

  // console.log(`firstname is ${firstNameRec}`);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleClick}
      contentLabel='Modal Here'
      style={customStyles}
      shouldCloseOnOverlayClick={false}
    >
      <div className='popClose' onClick={handleClick}></div>
      <Formik
        initialValues={{
          firstName: { firstNameRec },
          lastName: { lastNameRec },
        }}
        validationSchema={TrialSchema}
        onSubmit={(values) => {
          db.collection("users").add({
            firstName: values.firstName,
            lastName: values.lastName,
          });
        }}
      >
        {({ errors, touched }) => (
          <Form style={formStyle} className='trialForm'>
            <div className='popTitle'>Edit Profile</div>

            <div className='label'>First Name</div>
            <Field name='firstName' />
            {errors.firstName && touched.firstName ? (
              <div className='formErrors'>{errors.firstName}</div>
            ) : null}

            <div className='label'>Last Name</div>
            <Field name='lastName' />
            {errors.lastName && touched.lastName ? (
              <div className='formErrors'>{errors.lastName}</div>
            ) : null}

            <div className='label'>Job Title</div>
            <Field name='email' type='email' />
            {errors.email && touched.email ? (
              <div className='formErrors'>{errors.email}</div>
            ) : null}

            <div className='popup__actions'>
              <p className='popup__actions-cancel' onClick={handleClick}>
                Cancel
              </p>
              <button type='submit' className='popup_actions-save'>
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </ReactModal>
  );
}

export default PopupProfile;
