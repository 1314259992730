import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyDjG1UrfZSGRgpq2sAI95istxXduy9a15c",
  authDomain: "inhero-clone.firebaseapp.com",
  projectId: "inhero-clone",
  storageBucket: "inhero-clone.appspot.com",
  messagingSenderId: "1070510549244",
  appId: "1:1070510549244:web:bb5766e07463d439438f79",
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const auth = firebase.auth();

export { db, auth };
