import ReactModal from "react-modal";
import React, { useContext, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "./Popup.css";

const TrialSchema = Yup.object().shape({
  currPassword: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  newPassword: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  confirmPass: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
});

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px 0 0 10px",
    maxWidth: "440px",
    height: "475px",
    maxHeight: "100%",
    padding: "0px 18px 0px 30px",
    fontWeight: "500",
    zIndex: "500",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.58)",
    zIndex: "500",
  },
};

const formStyle = {
  display: "flex",
  flexDirection: "column",
};

function PopupPassword({ isOpen, setOpen }) {
  const handleClick = () => {
    setOpen(!isOpen);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleClick}
      contentLabel='Modal Here'
      style={customStyles}
      shouldCloseOnOverlayClick={false}
    >
      <div className='popClose' onClick={handleClick}></div>
      <Formik
        initialValues={{
          currPassword: "",
          newPassword: "",
          confirmPass: "",
        }}
        validationSchema={TrialSchema}
      >
        {({ errors, touched }) => (
          <Form style={formStyle} className='trialForm'>
            <div className='popTitle'>Change Password</div>

            <div className='label'>Current Password</div>
            <Field name='currPassword' type='password' />
            {errors.currPassword && touched.currPassword ? (
              <div className='formErrors'>{errors.currPassword}</div>
            ) : null}

            <div className='label'>New Password</div>
            <Field name='newPassword' type='password' />
            {errors.newPassword && touched.newPassword ? (
              <div className='formErrors'>{errors.newPassword}</div>
            ) : null}

            <div className='label'>Confirm New Password</div>
            <Field name='confirmPass' type='password' />
            {errors.confirmPass && touched.confirmPass ? (
              <div className='formErrors'>{errors.confirmPass}</div>
            ) : null}

            <div className='popup__actions'>
              <p className='popup__actions-cancel' onClick={handleClick}>
                Cancel
              </p>
              <button className='popup_actions-save'>Save</button>
            </div>
          </Form>
        )}
      </Formik>
    </ReactModal>
  );
}

export default PopupPassword;
