import React, { useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import "../pages/Settings.css";
import "./SettingsTeamPage.css";
import {
  useLocation,
  Link,
  Route,
  useRouteMatch,
  Switch,
} from "react-router-dom";
import Usertable from "../components/Usertable";
import Usercard from "../components/Usercard";

function SettingsTeamPageInvites() {
  let currentLocation = useLocation().pathname;

  let { path, url } = useRouteMatch();
  const [hover, sethover] = useState(true);

  return (
    <div>
      <Header />
      <div className='settings__body'>
        <Sidebar />

        <div className='settings-view-content-container'>
          <div className='locationwidget__pos'>{/* <LocationWidget /> */}</div>

          <div className='settings__header'>
            <h1 className='settings__header__title'>Team</h1>

            <div className='settings__header__options'>
              <Link to='/settings/team'>
                <p
                  className={
                    currentLocation === "/settings/team" && `options_hover`
                  }
                >
                  All Users
                </p>
              </Link>

              <Link to={`${url}/invites`}>
                <p
                  className={
                    currentLocation === "/settings/team/invites" &&
                    `options_hover`
                  }
                >
                  Invites
                </p>
              </Link>
            </div>
          </div>

          <Usertable>
            <Usercard avatar='CC' name='Chupa Chupa' locations='Lolly land' />
            <Usercard
              avatar='DG'
              name='Drakey Gonzo'
              locations='Lemonade stand'
            />
          </Usertable>
        </div>
      </div>
    </div>
  );
}

export default SettingsTeamPageInvites;
