import React, { useContext, useState } from "react";
import "./Header.css";
import ihLogo from "../images/ihLogo.svg";
import CreateIcon from "@material-ui/icons/Create";
import { Avatar } from "@material-ui/core";
import { Link } from "react-router-dom";
import { auth } from "../firebase";
import userContext from "../contexts/userContext";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ReactModal from "react-modal";
import PopupProfile from "./popups/PopupProfile";
import PopupPassword from "./popups/PopupPassword";

function Header() {
  const authCtx = useContext(userContext);

  const [isShowAccMenu, setIsShowAccMenu] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isPasswordOpen, setIsPasswordOpen] = useState(false);

  const accountPopupHandler = () => {
    setIsProfileOpen(!isProfileOpen);
    setIsShowAccMenu(false);
  };

  const passwordPopupHandler = () => {
    setIsPasswordOpen(!isPasswordOpen);
    setIsShowAccMenu(false);
  };

  return (
    <div>
      <div className='header'>
        <div className='header__left'>
          <div className='header__img'>
            <Link to='/'>
              <img src={ihLogo} alt='' />
            </Link>
          </div>

          <ul>
            <li>
              <Link to='/'>Inbox</Link>
            </li>
            <li>Referrals</li>
            <li>
              <Link to='/settings/team'>Settings</Link>
            </li>
          </ul>
        </div>

        <div className='header__right'>
          <button>
            <CreateIcon />

            <p> New Message</p>
          </button>
          <div className='header__support'>
            <p>Help</p>
          </div>

          <div
            className='header__user'
            onClick={() => setIsShowAccMenu(!isShowAccMenu)}
          >
            <Avatar>HA</Avatar>
            <p>Hassan</p>
          </div>

          {isShowAccMenu && (
            <div className='header__account__menu'>
              <div className='header__account__menu__profile'>
                <div className='header__account__menu__profile-left'>
                  <Avatar>HA</Avatar>
                </div>

                <div className='header__account__menu__profile-right'>
                  <p className='header__account__menu__profile-right-name'>
                    Daniel Adams
                  </p>
                  <p className='header__account__menu__profile-right-desc'>
                    {auth.currentUser.email || "loading"}
                  </p>
                </div>
              </div>

              <div className='header__account__menu__list'>
                <ul>
                  <li onClick={accountPopupHandler}>
                    <PersonOutlineIcon />
                    Edit Profile
                  </li>
                  <li onClick={passwordPopupHandler}>
                    <MailOutlineIcon />
                    Change Password
                  </li>
                  <li>
                    <NotificationsNoneIcon />
                    Notification Preferences
                  </li>
                  <li onClick={authCtx.logout}>
                    <ExitToAppIcon />
                    Sign Out
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>

      {isShowAccMenu && (
        <div className='backdrop' onClick={() => setIsShowAccMenu(false)} />
      )}

      {isProfileOpen && (
        <PopupProfile isOpen={isProfileOpen} setOpen={setIsProfileOpen} />
      )}

      {isPasswordOpen && (
        <PopupPassword isOpen={isPasswordOpen} setOpen={setIsPasswordOpen} />
      )}
    </div>
  );
}

export default Header;
