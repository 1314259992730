import React from "react";
import Usercard from "./Usercard";

function Usertable(props) {
  return (
    <div className='userTable'>
      <div className='userList__Headers'>
        <div style={{ minWidth: "55px" }}></div>
        <div className='UserList__HeaderItem'>Name</div>
        <div className='UserList__HeaderItem'>Location(s)</div>
        <div className='UserList__HeaderItem'>Role</div>
      </div>

      {props.children}
    </div>
  );
}

export default Usertable;
