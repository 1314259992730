import React, { useState } from "react";
import "../components/ResetPassword.css";
import { useHistory } from "react-router-dom";

function Password() {
  const [email, setEmail] = useState("");

  const history = useHistory();

  const resetPassHandler = (e) => {
    e.preventDefault();

    console.log(email);

    fetch(
      "https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=AIzaSyDc1pXapaloqdOzdoWHcMj03DLrCaKlYHA",
      {
        method: "POST",
        body: JSON.stringify({
          requestType: "PASSWORD_RESET",
          email: email,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    history.replace("/");

    setEmail(" ");
  };

  return (
    <div className='resetPassword'>
      <p className='resetPassword__title'>Let’s get you signed in. 😀</p>

      <p className='resetPassword__desc'>
        Enter the email address associated with your account, and we'll send you
        a link to help reset your password.
      </p>

      <form action='' className='resetPassword__container'>
        <input
          type='text'
          placeholder='Email'
          onChange={(x) => setEmail(x.target.value)}
        />
        <button type='submit' onClick={resetPassHandler}>
          Reset Password
        </button>
      </form>
    </div>
  );
}

export default Password;
