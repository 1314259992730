import React, { useState } from "react";
import Header from "../components/Header";
import LocationWidget from "../components/LocationWidget";
import Sidebar from "../components/Sidebar";
import "../pages/Settings.css";
import "./SettingsTeamPage.css";
import {
  useLocation,
  Link,
  Route,
  useRouteMatch,
  Switch,
} from "react-router-dom";

function SettingsTeamPage() {
  let currentLocation = useLocation().pathname;

  let { path, url } = useRouteMatch();

  const [hover, sethover] = useState(true);

  return (
    <div>
      <Header />
      <div className='settings__body'>
        <Sidebar />

        <div className='settings-view-content-container'>
          <div className='locationwidget__pos'>{/* <LocationWidget /> */}</div>

          <div className='settings__header'>
            <h1 className='settings__header__title'>Team</h1>

            <div className='settings__header__options'>
              <p
                className={
                  currentLocation === "/settings/team" && `options_hover`
                }
              >
                All Users
              </p>

              <Link to={`${url}/invites`}>
                <p>Invites</p>
              </Link>
            </div>
          </div>

          <div className='userTable'>
            <div className='userList__Headers'>
              <div style={{ minWidth: "55px" }}></div>
              <div className='UserList__HeaderItem'>Name</div>
              <div className='UserList__HeaderItem'>Location(s)</div>
              <div className='UserList__HeaderItem'>Role</div>
            </div>

            <div className='userRow-wrap'>
              <div className='userRow'>
                <div className='userRow__avatar'>DA</div>
                <div className='userRow__MainInfo'>Daniel Adams</div>
                <div className='userRow__Locations'>aa</div>
                <div className='userRow__RowActions'>a</div>
              </div>
            </div>

            <div className='userRow-wrap'>
              <div className='userRow'>
                <div className='userRow__avatar'>BB</div>
                <div className='userRow__MainInfo'>Bobby Jones</div>
                <div className='userRow__Locations'>aa</div>
                <div className='userRow__RowActions'>
                  <select>
                    <option>1</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingsTeamPage;
