import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Header from "./components/Header";
import CreateAccountPage from "./pages/CreateAccountPage";
import InboxPage from "./pages/InboxPage";
import LoginPage from "./pages/LoginPage";
import NotFound from "./pages/NotFound";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import SettingsTeamPage from "./pages/SettingsTeamPage";
import userContext from "./contexts/userContext";
import SettingsManageReviewSitesPage from "./pages/SettingsManageReviewSitesPage";
import SettingsTeamPageInvites from "./pages/SettingsTeamPageInvites";

function App() {
  const authCtx = useContext(userContext);

  return (
    <div>
      <Switch>
        <Route path='/' exact>
          {authCtx.isLoggedIn ? <InboxPage /> : <Redirect to='/login' />}
        </Route>

        {!authCtx.isLoggedIn && (
          <Route path='/login' exact>
            <LoginPage />
          </Route>
        )}

        {!authCtx.isLoggedIn && (
          <Route path='/reset-password' exact>
            <ResetPasswordPage />
          </Route>
        )}

        <Route path='/settings/managereviewsites' exact>
          {authCtx.isLoggedIn ? (
            <SettingsManageReviewSitesPage />
          ) : (
            <Redirect to='/login' />
          )}
        </Route>

        <Route path='/settings/team' exact>
          {authCtx.isLoggedIn ? <SettingsTeamPage /> : <Redirect to='/login' />}
        </Route>

        <Route path='/settings/team/invites' exact>
          {authCtx.isLoggedIn ? (
            <SettingsTeamPageInvites />
          ) : (
            <Redirect to='/login' />
          )}
        </Route>

        <Route path='*'>
          <NotFound />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
