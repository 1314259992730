import React from "react";
import "./Sidebar.css";
import { Switch, Route, useLocation, Link } from "react-router-dom";

const manageReviewSites = "/settings/managereviewsites";
const team = "/settings/team";
const teamInvites = "/settings/team/invites";

function Sidebar() {
  let currentLocation = useLocation().pathname;

  return (
    <div className='sidebar'>
      <div className='sidebar__items'>
        <p>SETTINGS</p>

        <ul>
          <Link to={manageReviewSites}>
            <li
              className={
                currentLocation === manageReviewSites &&
                `sidebar__items_li_selected`
              }
            >
              Manage Review Sites
            </li>
          </Link>

          <li>Templates</li>

          <Link to={team}>
            <li
              className={
                (currentLocation === team || currentLocation === teamInvites) &&
                `sidebar__items_li_selected`
              }
            >
              Team
            </li>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
