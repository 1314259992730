import React from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";

function SettingsManageReviewSitesPage() {
  return (
    <div>
      <Header />
      <Sidebar />
      <h1>Test</h1>
    </div>
  );
}

export default SettingsManageReviewSitesPage;
