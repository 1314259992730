import React from "react";
import Header from "../components/Header";

function InboxPage() {
  return (
    <div>
      <Header />
      <p>Inbox page</p>
    </div>
  );
}

export default InboxPage;
