import React from "react";
import "./LocationWidget.css";

function LocationWidget() {
  return (
    <div className='locationwidget'>
      <p>Northwood Dentist</p>
    </div>
  );
}

export default LocationWidget;
