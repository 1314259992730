import React, { useContext } from "react";
import "./Login.css";
import ihLogo from "../images/ihLogo.svg";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import userContext from "../contexts/userContext";
import { auth } from "../firebase";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const LoginSchema = Yup.object().shape({
  enteredEmail: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  enteredPassword: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
});

function Login() {
  const authCtx = useContext(userContext);
  const history = useHistory();

  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");

  const submitHandler = async (values) => {
    // setEmail(values.enteredEmail);
    // setPassword(values.enteredPassword);

    await auth
      .signInWithEmailAndPassword(values.enteredEmail, values.enteredPassword)
      .then((x) => {
        authCtx.login(x.user.refreshToken);
        history.replace("/");
      })
      .catch((error) => alert(error));
  };

  return (
    <div className='login'>
      <img src={ihLogo} alt='inhero_logo' className='login__logo' />
      <h1 className='login__title'>Sign in</h1>

      <Formik
        initialValues={{
          enteredEmail: "",
          enteredPassword: "",
        }}
        validationSchema={LoginSchema}
        onSubmit={(values) => submitHandler(values)}
      >
        {({ errors, touched }) => (
          <Form className='login__container'>
            <Field
              name='enteredEmail'
              type='text'
              placeholder='Email'

              // onChange={(e) => setEmail(e.target.value)}
            />
            {errors.enteredEmail && touched.enteredEmail ? (
              <div className='formErrors'>{errors.enteredEmail}</div>
            ) : null}
            <Field
              name='enteredPassword'
              type='password'
              placeholder='Password'

              // onChange={(e) => setPassword(e.target.value)}
            />
            {errors.enteredPassword && touched.enteredPassword ? (
              <div className='formErrors'>{errors.enteredPassword}</div>
            ) : null}
            <button type='submit'>Sign in</button>
          </Form>
        )}
      </Formik>

      <div className='login__options'>
        <p>
          <Link to='/reset-password'>Forgot Password?</Link>
        </p>
      </div>
    </div>
  );
}

export default Login;
